<template>
  <div class="zq-sidebar-logo-wrap">
    <div class="zq-sidebar-logo--dots">
      <CDotsIcon v-show="minimize" />
    </div>
    <img
      v-if="theme === 'default'"
      class="dropdown-toggle"
      size="custom-size"
      :height="brandLogoSize"
      :width="brandLogoSize"
      src="@/assets/icons/logo.svg"
      alt="logo"
    />
    <div v-else ref="iconLogo" class="zq-sidebar-logo"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CDotsIcon from '@/shared/UI/CDotsIcon';
import { getHashIcon } from '@/utils/hashIcon';

export default {
  props: {
    minimize: Boolean,
    spaceName: String,
  },
  components: {
    CDotsIcon,
  },
  created() {},
  computed: {
    ...mapGetters('theme', ['theme']),
    brandLogoSize() {
      switch (this.theme) {
        case 'main': {
          return 43;
        }
        case 'default': {
          return 40;
        }
        default: {
          return 40;
        }
      }
    },
    space() {
      return localStorage['zq-space-name'];
    },
  },
  mounted() {
    getHashIcon(this.$refs.iconLogo, localStorage['zq-space-name']);
  },
};
</script>

<style lang="scss">
.zq-sidebar-logo-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  .zq-sidebar-logo--dots {
    width: 16px;
    height: 16px;
  }
}

.zq-sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  //transform: rotate(-45deg);
  margin-left: 0.7rem;
}
</style>
